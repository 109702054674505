html, body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

a {
  color: #000000;
  word-break: break-all;
}

.bg-gradiant {
  background: linear-gradient(180deg, #A6CE39 0.81%, #1C9C41 28.5%);
  height: 100vh;
}

legend span {
  margin-left: 2px;
}

main {
  width: 100%;
  padding: 30px 15px !important;
}

.hide {
  display: none;
}

.display {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bold {
  font-weight: bold;
}

.inline {
  display: inline;
  vertical-align: middle;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #f00;
  border-radius: 50%;
  display: inline-block;
}

.dot-border {
  color: #f00;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 2px;
  border-radius: 16px;
  border: solid 2px currentColor;
  background-clip: content-box;
  background-color: currentColor;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #1C9C41;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.MuiTextField-root p {
  color:rgba(255,0,0,1);
}